import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PrivacyPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  background-color: var(--white);
  color: var(--text);
  min-height: 100vh;
  padding: 100px 0;
  box-sizing: border-box;
`;

const PrivacyPageInner = styled.div`
  max-width: 600px;
`;

const LastUpdated = styled.h2`
  font-style: italic;
  font-size: 1.5em;
`;

const Privacy = () => {
  return (
    <div className="site">
      <div className="site-inner">
        <PrivacyPage>
          <PrivacyPageInner>
            <h1>Ramjet Studios Privacy Policy</h1>
            <LastUpdated>Last updated January 19, 2024.</LastUpdated>
            <h2>1. Summary</h2>
            <p>This document tells you everything about the information we collect about you.</p>
            <p>It also tells you what we do with that information and why.</p>
            <p>
              The only reasons we collect personally identifying data is for legal compliance reasons (e.g. figuring out what tax we owe),
              account management (you can log into your account, we can verify it's you) and for marketing reasons (if you want).
            </p>
            <p>
              We collect personal data when you submit it to us, either from creating or amending your Ramjet account, from when you contact
              us, or from purchasing something from our website.
            </p>
            <p>
              We do not sell any personal information. We share it with some businesses that help us do business; for example, we might
              automatically set your name on our payment processor in order to find your transactions there faster.
            </p>
            <p>We do not keep your data longer than we need it.</p>
            <p>
              You have the right to request the deletion of some or all of your personal data. You also have the right to request a copy of
              all the data we have on you. You also have the right to ask us to correct your data.
            </p>
            <p>To do any of the above, or if you have any questions or concerns, please email legal@ramjetstudios.com.</p>
            <h2>2. Ramjet Studios</h2>
            <p>
              We are Kyle Windsor DBA Ramjet Studios. We are registered in London, Ontario, Canada with business number 310531215. We are
              the legal data controller for the data stored on our servers.
            </p>
            <h2>3. Application</h2>
            <p>
              This policy applies for all of our games, our websites, support services, and social media accounts, as well as any other
              products or services we offer. This policy applies to you whenever you interact with any of these products or services.
              Personal data is any data about you or that identifies you.
            </p>
            <h2>4. Children</h2>
            <p>
              You must be at least 13 years of age to use our products and services. We do not knowingly collect personal data of any person
              under 13 years old without parent or guardian consent. If you are a parent or guardian and are concerned about the processing
              of your child's personal data, please contact legal@ramjetstudios.com.
            </p>
            <h2>5. Data We Collect</h2>
            <ul>
              <li>Email address: We use this to contact or respond to you. This is collected if you sign up for a Ramjet account.</li>
              <li>
                Real name: We use this to identify you. This is collected if you add it to your Ramjet account or purchase something and
                provide a name.
              </li>
              <li>
                Country, state or province, and postal code: We use this for accounting purposes. We store this so we can figure out what
                taxes to pay the government at the end of the year. This is collected if you purchase something.
              </li>
              <li>
                Your SteamID: We use this to link your Ramjet account with your Steam account. We store this anonymously when you play a
                game or explicitly when you link your Ramjet account to your Steam account.
              </li>
              <li>
                Your IP address: We use this for player safety and anti-fraud purposes. We collect this when you play online or make a
                purchase from our website.
              </li>
            </ul>
            <p>
              The reason we collect data automatically is because it's necessary to run services. If you provide data voluntarily, the data
              is collected because of legitimate interest and within the basis of your consent.
            </p>
            <h2>6. Usage</h2>
            <p>
              We use your personal data to run, maintain, and improve our services, as well as to communicate with you. We use your data
              primarily to make our games available for play, and secondarily to provide related services to you. We also use your
              non-identifying data to monitor service performance (e.g. analytics, bug reporting, dealing with cheating, and protecting you
              and other users.)
            </p>
            <p>
              We share your data with some other businesses (for example, we may query Steam with your consent for your usage data). We do
              not sell your personal data, and frankly have no interest in doing so.
            </p>
            <p>
              We may use your data to comply with our legal obligations (e.g. computing tax or complying with Digital Millenium Copyright
              Act requests).
            </p>
            <p>
              If you give consent, we will use your personal data to give you marketing updates on occasion. We will never share your
              information with marketing companies unless you give us consent to do so.
            </p>
            <h2>7. Controllers</h2>
            <p>
              Steam collects personal data about people who play our games. They do not share this with us. Steam is the controller for this
              data.{' '}
              <a href="https://store.steampowered.com/privacy_agreement/" target="_blank" rel="noreferrer" className="brand-link">
                You can find their privacy policy here
              </a>
              .
            </p>
            <p>
              If you upload data, such as gameplay footage, to social media platforms, these platforms will be the controller of the data
              you share.
            </p>
            <p>
              If you purchase something from our website, our payment processor is the controller of your data. We do not have access to
              your financial or payment data.
            </p>
            <p>
              In the event that Ramjet Studios is reorganized or merged, we may transfer personal data to a third party. This third party
              will protect data at least to the same level as described in this policy.
            </p>
            <h2>8. Duration</h2>
            <p>
              We keep personal data only as long as we need to. If you have an account with us registered via email, we will keep it as long
              as you remain active.
            </p>
            <p>
              If we are legally required to hold information for longer, for example to comply with law enforcement, we will also keep your
              personal data.
            </p>
            <h2>9. Security</h2>
            <p>
              We have various security requirements in place to keep your data from being lost or stolen. We use third-party organizations
              for data processing in some cases (for example, our payment processor) which legally must comply with strict data protection
              laws. We ensure the security of any services we use to process personal information.
            </p>
            <h2>10. Rights</h2>
            <p>
              You have rights over the information we hold about you. You can ask us to delete or modify your data, as well as to request a
              copy. In particular, you have the right to:
            </p>
            <ul>
              <li>request a copy of some or all personal data we have about you</li>
              <li>request a change of some or all personal data we have about you</li>
              <li>request a deletion of some or all personal data we have about you</li>
              <li>object to automated decisions being made about you</li>
              <li>ask us to stop using your information</li>
              <li>ask us to share your information with another company</li>
              <li>withdraw consent for any opt-in communications, regardless of if you have already provided it</li>
            </ul>
            <p>To do so, please contact legal@ramjetstudios.com.</p>
            <p>
              These rights are qualified, meaning that if there are circumstances blocking the exercise of these rights (such as legal
              compliance) we cannot comply.
            </p>
            <p>Once requested, action will be taken within one calendar month.</p>
            <h2>11. Changes</h2>
            <p>
              This policy may change over time pursuant to business and legal changes. We will make an effort to let you know when this
              happens. You may then agree or disagree to the changes. If you disagree to the changes, we won't be able to provide our games
              and services to you.
            </p>
            <p>
              <Link to="/" className="brand-link">
                Back to Home
              </Link>
            </p>
          </PrivacyPageInner>
        </PrivacyPage>
      </div>
    </div>
  );
};

export default Privacy;
