import { atom } from 'jotai';
import IUser from '../models/User';
import ISubscription from '../models/Subscription';

const storedUser = localStorage.getItem('user');
const userAtomInternal = atom<IUser | undefined>(storedUser ? (JSON.parse(storedUser) as IUser) : undefined);
export const userAtom = atom(
  (get) => get(userAtomInternal),
  (get, set, value: IUser | undefined) => {
    if (!value) {
      localStorage.removeItem('user');
    } else {
      localStorage.setItem('user', JSON.stringify(value));
    }
    set(userAtomInternal, value);
  }
);
export const subscriptionsAtom = atom<ISubscription[]>([]);
export const subscriptionsSetAtom = atom(false);
