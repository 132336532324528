import * as React from 'react';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { userAtom } from '../../../state';
import { IStats } from '../../../models/Subscription';
import { API_URL } from '../../../mode';
import Loader from '../../elements/loader';

const Page = styled.div`
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: flex-start;

  @media screen and (max-width: 1000px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const LoginPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  background-color: var(--white);
  color: var(--text);
  min-height: 100%;
  padding: 100px;
  box-sizing: border-box;

  width: 50%;

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding: 50px;
    min-height: auto;
  }
`;

const FormContainer = styled.div`
  width: auto;
  display: inline-block;
  width: 400px;
`;

const InfoSegment = styled.div`
  width: 50%;

  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (max-width: 1000px) {
    width: 100%;
    height: auto;
  }
`;

const InfoSegmentInner = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 100px;
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 1000px) {
    height: auto;
    padding: 50px;
  }
`;

const Stats = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const Stat = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const StatLabel = styled.div`
  text-transform: uppercase;
  font-size: 0.8em;
  opacity: 0.8;
  margin-bottom: 4px;
`;

const StatValue = styled.div`
  text-transform: uppercase;
  font-family: 'Arvin', 'Arial Narrow', sans-serif;
  font-size: 3em;
  font-weight: 300;
`;

const AboutParagraph = styled.p`
  max-width: 600px;
`;

const CostGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const CostRow = styled.div`
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
`;

const CostHeader = styled.div``;

const CostTitle = styled.div`
  margin-right: 50px;
  font-family: 'Arvin', 'Arial Narrow', sans-serif;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-weight: 300;
  font-size: 1.6em;
`;

const CostPrice = styled.div`
  text-transform: uppercase;
  font-weight: 300;
`;

const CostDescription = styled.div`
  margin-top: 20px;
`;

const MembershipPage = () => {
  const [user, _] = useAtom(userAtom);

  const [stats, setStats] = React.useState<IStats | undefined>(undefined);
  const [statsLoading, setStatsLoading] = React.useState(true);

  React.useEffect(() => {
    setStatsLoading(true);
    try {
      fetch(`${API_URL}/payment`).then((resp) => {
        if (resp.status === 200) {
          resp.json().then((obj) => {
            setStats(obj);

            setStatsLoading(false);
          });
        } else {
          setStatsLoading(false);
        }
      });
    } catch (err) {
      console.error(err);
      setStatsLoading(false);
    }
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const emailCode = searchParams.get('email_code');
  React.useEffect(() => {
    if (emailCode && user) {
      fetch(`${API_URL}/auth/email/${emailCode}`, {
        method: 'PATCH',
        headers: { Authorization: user.token, 'Content-Type': 'application/json' },
      }).then(() => {
        setSearchParams((prev) => {
          prev.delete('email_code');
          return prev;
        });
      });
    }
  }, [user, emailCode]);

  return (
    <div className="site">
      <Page>
        <LoginPage>
          <FormContainer>
            <Outlet />
          </FormContainer>
        </LoginPage>
        <InfoSegment>
          <InfoSegmentInner>
            <h1>Membership</h1>
            <p>If you want to support the development of VEIN, you can donate here.</p>
            <p>You will not receive anything from this other than a good feeling in your heart.</p>
            {stats ? (
              <Stats>
                <Stat>
                  <StatLabel>Members</StatLabel>
                  <StatValue>{stats.members}</StatValue>
                </Stat>
                <Stat>
                  <StatLabel>Monthly Donations</StatLabel>
                  <StatValue>${Math.floor(stats.monthly_revenue)}</StatValue>
                </Stat>
                <Stat>
                  <StatLabel>One-Time Donations (30 days)</StatLabel>
                  <StatValue>${Math.floor(stats.last_month)}</StatValue>
                </Stat>
              </Stats>
            ) : statsLoading ? (
              <Loader $white="true" />
            ) : (
              <></>
            )}
            <h2>About</h2>
            <AboutParagraph>
              We have been working on VEIN for over a year now completely out-of-pocket. We're only two developers, so any help is
              appreciated. While we don't need donations to continue working on VEIN, it will help offset costs.
            </AboutParagraph>
            <AboutParagraph>
              As above, you don't receive anything special from donating. However, it lets us focus more on developing the game and less on
              our budget.
            </AboutParagraph>
            <h2>Current Costs</h2>
            <CostGrid>
              <CostRow>
                <CostHeader>
                  <CostTitle>Hosting</CostTitle>
                  <CostPrice>$85/month</CostPrice>
                </CostHeader>
                <CostDescription>This includes the VEIN website, Ramjet website, Discord bot, server browser, etc.</CostDescription>
              </CostRow>
              <CostRow>
                <CostHeader>
                  <CostTitle>Notion</CostTitle>
                  <CostPrice>$20/month</CostPrice>
                </CostHeader>
                <CostDescription>This is our task planning software, lore bible, and organizational system.</CostDescription>
              </CostRow>
              <CostRow>
                <CostHeader>
                  <CostTitle>GitHub</CostTitle>
                  <CostPrice>$85/month</CostPrice>
                </CostHeader>
                <CostDescription>This is where we store and sync the game's files.</CostDescription>
              </CostRow>
              <CostRow>
                <CostHeader>
                  <CostTitle>Translation System</CostTitle>
                  <CostPrice>$0/month (new system pending)</CostPrice>
                </CostHeader>
                <CostDescription>
                  Lets us localize the game into other languages so everyone, globally, can play comfortably in their native language.
                </CostDescription>
              </CostRow>
              <CostRow>
                <CostHeader>
                  <CostTitle>Art</CostTitle>
                  <CostPrice>~$200/month</CostPrice>
                </CostHeader>
                <CostDescription>
                  This is us buying game art like animations, characters, sounds, and other stuff directly used in the game.
                </CostDescription>
              </CostRow>
            </CostGrid>
            <h2>Goals</h2>
            <CostGrid>
              <CostRow>
                <CostHeader>
                  <CostTitle>Dedicated Test Server</CostTitle>
                  <CostPrice>$120/month</CostPrice>
                </CostHeader>
                <CostDescription>
                  A server that we can use to test multiplayer easier. This will help fix multiplayer bugs way faster.
                </CostDescription>
              </CostRow>
              <CostRow>
                <CostHeader>
                  <CostTitle>Build Server</CostTitle>
                  <CostPrice>$40/month</CostPrice>
                </CostHeader>
                <CostDescription>
                  A server that automatically generates VEIN builds. This will allow for daily game updates and give the devs back a couple
                  hours per day.
                </CostDescription>
              </CostRow>
              <CostRow>
                <CostHeader>
                  <CostTitle>More Art</CostTitle>
                </CostHeader>
                <CostDescription>
                  We have a large wishlist of art that we'd like to buy. Any excess donations will go towards this as well as runway for the
                  above.
                </CostDescription>
              </CostRow>
            </CostGrid>
            <hr />
            <p>
              Problem with your account or subscription? Need a refund?
              <br />
              Email{' '}
              <a href="mailto:hello@ramjetstudios.com" className="white-link">
                hello@ramjetstudios.com
              </a>{' '}
              and we will sort it out for you.
            </p>
            <p>
              <Link to="/" className="white-link">
                Back to Home
              </Link>
            </p>
          </InfoSegmentInner>
        </InfoSegment>
      </Page>
    </div>
  );
};

export default MembershipPage;
