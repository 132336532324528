import { useAtom } from 'jotai';
import * as React from 'react';
import styled from 'styled-components';
import { userAtom } from '../../../state';
import { Error } from '../../elements/typography';
import { API_URL } from '../../../mode';
import Loader from '../../elements/loader';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

const RegisterLinkContainer = styled.div`
  margin-top: 20px;
  a {
    cursor: pointer;
  }
`;

interface IResetPasswordForm {
  password: string;
}

const ResetPassword = () => {
  const form = useForm<IResetPasswordForm>();
  const [user, _] = useAtom(userAtom);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [qp] = useSearchParams();

  const login = async (payload: IResetPasswordForm) => {
    setError('');
    setLoading(true);

    try {
      await fetch(`${API_URL}/auth/forgot-password/${qp.get('email_code')}`, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setDone(true);
    } catch (err) {
      setError("Couldn't login. Try again later.");
    }

    setLoading(false);
  };

  if (user) {
    return <Navigate to="/membership/donate" />;
  }

  return done ? (
    <>
      <div>Your password has been reset.</div>
      <RegisterLinkContainer>
        <Link className="brand-link" to="/membership">
          Login
        </Link>
      </RegisterLinkContainer>
    </>
  ) : (
    <>
      <div className="form">
        <form autoComplete="on" onSubmit={form.handleSubmit(login)}>
          <label>New Password</label>
          <input
            className="input"
            type="password"
            placeholder="p@ssw0rd"
            autoComplete="new-password"
            {...form.register('password', { required: true })}
          />
          {form.formState.errors.password?.type === 'required' && <Error>Password is required</Error>}
          {loading ? <Loader /> : <button type="submit">Reset Password</button>}
          {error && <Error>{error}</Error>}
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
