import * as React from 'react';
import PeopleCard from './PeopleCard';
import Kyle from '../../../../assets/people/kyle.jpg';
import KyleThumb from '../../../../assets/people/kyle.jpg?width=100&height=100';
import Azi from '../../../../assets/people/azi.png';
import AziThumb from '../../../../assets/people/azi.png?width=100&height=100';

const About: React.FC = () => {
  return (
    <section className="section about">
      <div className="section-content">
        <h1>About</h1>
        <p>
          The{' '}
          <a href="https://en.wikipedia.org/wiki/Bussard_ramjet" target="_blank">
            Bussard Ramjet
          </a>{' '}
          was introduced in 1960 with a proposal by the eponymous{' '}
          <a href="https://en.wikipedia.org/wiki/Robert_W._Bussard" target="_blank">
            Robert Bussard
          </a>
          . Conceptually, the Ramjet uses giant electromagnetic fields to collect nuclear fissile material from the interstellar medium as
          it travels. The faster it travels, the more it collects; the more it collects, the more it can fuse and use as power.
        </p>
        <p>
          Ramjet Studios was founded in 2017. We are a small, remote-based game studio comprised of people who get inspired by our own
          games. As with the ramjet, our driving force is our acceleration: we make the games we want to play.
        </p>
        <h2>People</h2>
        <div className="people">
          <PeopleCard
            name="Kyle Windsor"
            title="Lead Programmer"
            socials={[
              /*{ type: 'email', email: 'kyle@ramjetstudios.com' },*/
              { type: 'website', url: 'https://kylewindsor.io' },
              { type: 'twitter', username: 'ramjetdiss' },
              { type: 'github', username: 'disseminate' },
              { type: 'twitch', username: 'disseminate4' },
            ]}
            avatar={Kyle}
            avatarThumb={KyleThumb}
          />
          <PeopleCard
            name="Azi"
            title="Lead Level Designer"
            socials={
              [
                // { type: 'website', url: 'https://www.azileveldesign.co/' },
                // { type: 'twitter', username: 'asoleris' },
                // { type: 'artstation', username: 'asoleris' },
              ]
            }
            avatar={Azi}
            avatarThumb={AziThumb}
          />
        </div>
      </div>
    </section>
  );
};

export default About;
