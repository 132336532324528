import styled from 'styled-components';

interface ILoaderProps {
  $white?: boolean;
}

const Loader = styled.div<ILoaderProps>`
  width: 24px;
  aspect-ratio: 1;
  display: grid;
  border: 2px solid #0000;
  border-radius: 50%;
  border-right-color: ${(props) => (props.$white ? 'var(--white)' : 'var(--brand-color)')};
  animation: l15 1s infinite linear;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: l15 2s infinite;
  }
  &::after {
    margin: 8px;
    animation-duration: 3s;
  }
  @keyframes l15 {
    100% {
      transform: rotate(1turn);
    }
  }
`;

export default Loader;
