import * as React from 'react';
import { createRoot } from 'react-dom/client';
import Home from './components/pages/home';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, Navigate } from 'react-router-dom';
import ErrorPage from './components/pages/error-page';
import OpenPage from './components/pages/open';
import MembershipPage from './components/pages/membership';
import Privacy from './components/pages/privacy';
import TOS from './components/pages/terms-of-service';
import Login from './components/pages/membership/Login';
import Register from './components/pages/membership/Register';
import Donations from './components/pages/membership/Donations';
import Account from './components/pages/membership/Account';
import ForgotPassword from './components/pages/membership/ForgotPassword';
import ResetPassword from './components/pages/membership/ResetPassword';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Home />} errorElement={<ErrorPage />} />
      <Route path="/open" element={<OpenPage />} />
      <Route path="/membership" element={<MembershipPage />}>
        <Route index element={<Navigate to="/membership/login" />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="donate" element={<Donations />} />
        <Route path="account" element={<Account />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="password-reset" element={<ResetPassword />} />
      </Route>
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/tos" element={<TOS />} />
    </>
  )
);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<App />);
