import styled from 'styled-components';

export const Error = styled.div`
  margin: 10px 0;
  color: #ff1111;
`;
export const ErrorLight = styled.div`
  margin-top: 10px;
  opacity: 0.8;
`;
