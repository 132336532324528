import * as React from 'react';
import Logo from '../../../../assets/logo.png?as=webp';

const Hero: React.FC = () => {
  return (
    <section className="hero">
      <div className="logo">
        <img src={Logo} alt="Ramjet Studios" width="800" height="800" />
      </div>
    </section>
  );
};

export default Hero;
