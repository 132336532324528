import { useAtom } from 'jotai';
import * as React from 'react';
import styled from 'styled-components';
import { userAtom } from '../../../state';
import { Error } from '../../elements/typography';
import { API_URL } from '../../../mode';
import Loader from '../../elements/loader';
import { Link, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX } from '../../../email';

const RegisterLinkContainer = styled.div`
  margin-top: 20px;
  a {
    cursor: pointer;
  }
`;

interface ILoginForm {
  email: string;
  password: string;
}

const Login = () => {
  const form = useForm<ILoginForm>();
  const [user, setUser] = useAtom(userAtom);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const login = async (payload: ILoginForm) => {
    setError('');
    setLoading(true);

    try {
      const resp = await fetch(`${API_URL}/auth/login`, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (resp.status === 200) {
        const obj = await resp.json();
        setUser(obj);
      } else {
        try {
          const obj = await resp.json();
          if (obj.error === 'bad_body') {
            setError('Invalid data. Make sure everything is filled in.');
          } else if (obj.error === 'invalid_auth') {
            setError('Invalid email or password.');
          }
        } catch (err) {
          setError("Couldn't login. Try again later.");
        }
      }
    } catch (err) {
      setError("Couldn't login. Try again later.");
    }

    setLoading(false);
  };

  if (user) {
    return <Navigate to="/membership/donate" />;
  }

  return (
    <>
      <div className="form">
        <form autoComplete="on" onSubmit={form.handleSubmit(login)}>
          <label>E-mail</label>
          <input
            className="input"
            type="email"
            placeholder="you@domain.com"
            autoComplete="email"
            {...form.register('email', { required: true, pattern: EMAIL_REGEX })}
          />
          {form.formState.errors.email?.type === 'required' && <Error>Email is required</Error>}
          {form.formState.errors.email?.type === 'pattern' && <Error>Invalid email</Error>}
          <label>Password</label>
          <input
            className="input"
            type="password"
            placeholder="p@ssw0rd"
            autoComplete="current-password"
            {...form.register('password', { required: true })}
          />
          {form.formState.errors.password?.type === 'required' && <Error>Password is required</Error>}
          {loading ? <Loader /> : <button type="submit">Login</button>}
          {error && <Error>{error}</Error>}
        </form>
        <RegisterLinkContainer>
          or{' '}
          <Link className="brand-link" to="/membership/register">
            Register
          </Link>
        </RegisterLinkContainer>
        <RegisterLinkContainer>
          <Link className="brand-link" to="/membership/forgot-password">
            Forgot Password
          </Link>
        </RegisterLinkContainer>
      </div>
    </>
  );
};

export default Login;
