import * as React from 'react';
import VeinLogo from '../../../../assets/vein-logo.png?as=webp';
import { Link } from 'react-router-dom';

const Vein: React.FC = () => {
  return (
    <section className="section vein">
      <div className="section-content">
        <div className="logo-container">
          <a href="https://vein.gg" target="_blank" className="vein-logo-link">
            <img src={VeinLogo} alt="VEIN" width="800" height="800" className="vein-logo" />
          </a>
        </div>
        <p>Vein is a single- and multiplayer open world zombie apocalypse survival RPG.</p>
        <p>We've been working on it since February 2022.</p>
        <p>
          We focus more on interaction, discovery, narrative, and the world
          <br />
          than on guns, combat, and inventories.
        </p>
        <p>
          Check out the website at{' '}
          <a href="https://vein.gg" target="_blank">
            https://vein.gg
          </a>
          .
        </p>
        <p>
          Want to help out? <Link to="/membership">Learn about membership here</Link>.
        </p>
      </div>
    </section>
  );
};

export default Vein;
