import { useAtom } from 'jotai';
import * as React from 'react';
import styled from 'styled-components';
import { userAtom } from '../../../state';
import { Error } from '../../elements/typography';
import { API_URL } from '../../../mode';
import Loader from '../../elements/loader';
import { Link, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX } from '../../../email';

const LoginLinkContainer = styled.div`
  a {
    cursor: pointer;
  }
`;

const LegalContainer = styled.div`
  font-size: 0.8em;
`;

const FormFooter = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface IRegisterForm {
  email: string;
  password: string;
}

const Register = () => {
  const form = useForm<IRegisterForm>();
  const [user, setUser] = useAtom(userAtom);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const Register = async (payload: IRegisterForm) => {
    setError('');
    setLoading(true);

    try {
      const resp = await fetch(`${API_URL}/auth/register`, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (resp.status === 201) {
        const obj = await resp.json();
        setUser(obj);
      } else {
        try {
          const obj = await resp.json();
          if (obj.error === 'email_exists') {
            setError('That email is already registered.');
          } else if (obj.error === 'bad_body') {
            setError('Invalid data. Make sure everything is filled in.');
          }
        } catch (err) {
          setError("Couldn't register. Try again later.");
        }
      }
    } catch (err) {
      setError("Couldn't login. Try again later.");
    }

    setLoading(false);
  };

  if (user) {
    return <Navigate to="/membership/donate" />;
  }

  return (
    <div className="form">
      <form autoComplete="on" onSubmit={form.handleSubmit(Register)}>
        <label>E-mail</label>
        <input
          className="input"
          type="email"
          placeholder="you@domain.com"
          autoComplete="email"
          {...form.register('email', { required: true, pattern: EMAIL_REGEX })}
        />
        {form.formState.errors.email?.type === 'required' && <Error>Email is required</Error>}
        {form.formState.errors.email?.type === 'pattern' && <Error>Invalid email</Error>}
        <label>Password</label>
        <input
          className="input"
          type="password"
          placeholder="p@ssw0rd"
          autoComplete="new-password"
          {...form.register('password', { required: true })}
        />
        {form.formState.errors.password?.type === 'required' && <Error>Password is required</Error>}
        {loading ? <Loader /> : <button type="submit">Register</button>}
        {error && <Error>{error}</Error>}
      </form>
      <FormFooter>
        <LoginLinkContainer>
          or{' '}
          <Link className="brand-link" to="/membership/login">
            Login
          </Link>
        </LoginLinkContainer>
        <LegalContainer>
          <Link to="/privacy" className="brand-link">
            Privacy
          </Link>{' '}
          |{' '}
          <Link to="/tos" className="brand-link">
            Terms
          </Link>
        </LegalContainer>
      </FormFooter>
    </div>
  );
};

export default Register;
