import * as React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div className="site">
      <div className="site-inner">
        <div className="error-page">
          <div>
            <h1>Error 404</h1>
            <p>You're in the wrong place.</p>
            <p>
              <Link to="/" className="brand-link">
                Home
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
