import * as React from 'react';
import Discord from '../../../../assets/socials/discord.svg';
import Youtube from '../../../../assets/socials/youtube.svg';
import Twitter from '../../../../assets/socials/twitter.svg';
import Steam from '../../../../assets/socials/steam.svg';
import Facebook from '../../../../assets/socials/facebook.svg';

const Contact: React.FC = () => {
  return (
    <section className="section white about">
      <div className="section-content">
        <h1>Contact</h1>
        <div>
          <p>
            Feel free to send us an email for any reason at <a href="mailto:hello@ramjetstudios.com">hello@ramjetstudios.com</a>. Seriously,
            any reason. We're happy to respond - or just chat.
          </p>
        </div>
        <h2>Socials</h2>
        <p>We don't spam. We only post things we think you'd get excited by.</p>
        <div className="social-row">
          <a href="https://store.steampowered.com/dev/ramjet" target="_blank" className="social-link">
            <img src={Steam} alt="Steam" width="48" height="48" />
          </a>
          <a href="https://twitter.com/veinsurvival" target="_blank" className="social-link">
            <img src={Twitter} alt="Twitter" width="48" height="48" />
          </a>
          <a href="https://facebook.com/veinsurvival" target="_blank" className="social-link">
            <img src={Facebook} alt="Facebook" width="48" height="48" />
          </a>
          <a href="https://discord.gg/MMxGTZRgHM" target="_blank" className="social-link">
            <img src={Discord} alt="Discord" width="48" height="48" />
          </a>
          <a href="https://www.youtube.com/channel/UCA3wa_YroX3PP3vSjZE4zEg" target="_blank" className="social-link">
            <img src={Youtube} alt="Youtube" width="48" height="48" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
