import { useAtom } from 'jotai';
import * as React from 'react';
import styled from 'styled-components';
import { userAtom } from '../../../state';
import { Error } from '../../elements/typography';
import { API_URL } from '../../../mode';
import Loader from '../../elements/loader';
import { Link, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX } from '../../../email';

const RegisterLinkContainer = styled.div`
  margin-top: 20px;
  a {
    cursor: pointer;
  }
`;

interface IForgotPasswordForm {
  email: string;
}

const Login = () => {
  const form = useForm<IForgotPasswordForm>();
  const [user, _] = useAtom(userAtom);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);

  const login = async (payload: IForgotPasswordForm) => {
    setError('');
    setLoading(true);

    try {
      await fetch(`${API_URL}/auth/forgot-password`, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setDone(true);
    } catch (err) {
      setError("Couldn't login. Try again later.");
    }

    setLoading(false);
  };

  if (user) {
    return <Navigate to="/membership/donate" />;
  }

  return done ? (
    <>
      <div>A password reset email has been sent to you.</div>
      <RegisterLinkContainer>
        <Link className="brand-link" to="/membership">
          Back
        </Link>
      </RegisterLinkContainer>
    </>
  ) : (
    <>
      <div className="form">
        <form autoComplete="on" onSubmit={form.handleSubmit(login)}>
          <label>E-mail</label>
          <input
            className="input"
            type="email"
            placeholder="you@domain.com"
            autoComplete="email"
            {...form.register('email', { required: true, pattern: EMAIL_REGEX })}
          />
          {form.formState.errors.email?.type === 'required' && <Error>Email is required</Error>}
          {form.formState.errors.email?.type === 'pattern' && <Error>Invalid email</Error>}
          {loading ? <Loader /> : <button type="submit">Send Reset Password Email</button>}
          {error && <Error>{error}</Error>}
        </form>
        <RegisterLinkContainer>
          <Link className="brand-link" to="/membership">
            Back
          </Link>
        </RegisterLinkContainer>
      </div>
    </>
  );
};

export default Login;
