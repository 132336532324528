import * as React from 'react';
import { Link } from 'react-router-dom';
import { IRamjetStats } from '../../../models/Stats';
import { API_URL } from '../../../mode';
import { Error } from '../../elements/typography';
import Loader from '../../elements/loader';

interface IMetricProps {
  label: string;
  value: React.ReactNode;
  subtitle?: string;
}

const Metric = (props: IMetricProps) => {
  return (
    <div className="metric">
      <div className="metric-label">{props.label}</div>
      <div className="metric-value">{props.value}</div>
      {props.subtitle && <div className="metric-label">{props.subtitle}</div>}
    </div>
  );
};

const OpenPage = () => {
  const [stats, setStats] = React.useState<IRamjetStats | undefined>();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);

    fetch(`${API_URL}/stats`)
      .then((resp) => {
        if (resp.status === 200) {
          resp.json().then((obj) => {
            setStats(obj);

            setLoading(false);
          });
        }
      })
      .catch((err) => {
        console.error(err);

        setLoading(false);
      });
  }, []);

  return (
    <div className="site">
      <div className="site-inner">
        <div className="open-page">
          <div className="open-page-inner">
            <h1>Open Metrics</h1>
            <p>We proudly make our metrics transparent.</p>
            <p>This page is updated every so often with our up-to-date stats, but it may be out of date.</p>
            {loading ? (
              <Loader />
            ) : stats ? (
              <>
                <p>Last updated {stats.lastUpdated}.</p>
                <p>
                  <Link to="/" className="brand-link">
                    Back to Home
                  </Link>
                </p>
                <div className="metric-container-2 metric-spacer">
                  <Metric label="Approx. Monthly Revenue" value={`$${Math.round(stats?.meanMonthlyRevenue || 0)}`} />
                  <Metric label="Approx. Monthly Expenditure" value={`$${Math.round(stats?.meanMonthlyExpenditure || 0)}`} />
                </div>
                <div className="metric-container-2">
                  <Metric label="Steam Wishlists" value={stats.vein.wishlists.toLocaleString()} />
                  {stats.steam.wishlistRank > 0 ? (
                    <Metric label="Wishlist Rank" value={`#${stats.steam.wishlistRank.toLocaleString()}`} />
                  ) : undefined}
                  {stats.steam.followers > 0 ? (
                    <Metric label="Steam Followers" value={`${stats.steam.followers.toLocaleString()}`} />
                  ) : undefined}
                  <Metric label="Steam Impressions" value={stats.vein.impressions.toLocaleString()} />
                  <Metric label="Steam Demo Licenses" value={stats.vein.demoLicenses.toLocaleString()} />
                  <Metric label="Steam Demo Unique Users" value={stats.vein.demoUniqueUsers.toLocaleString()} />
                  <Metric label="Steam Demo DAU (7-day mean)" value={stats.vein.demoDAU.toLocaleString()} />
                  <Metric label="Steam Demo Median Playtime" value={`${stats.vein.demoMedianPlaytime} min`} />
                </div>
                <div className="metric-container-2">
                  <Metric label="YouTube Subscribers" value={`${(stats.youtube.subscribers / 1000).toFixed(1)}K`} />
                  <Metric label="YouTube Views" value={`${(stats.youtube.views / 1000).toFixed(1)}K`} />
                  <Metric label="YouTube Watch-Hours" value={`${(stats.youtube.watchHours / 1000).toFixed(1)}K`} />
                  <Metric label="YouTube Mean Monthly Revenue" value={`$${Math.round(stats?.youtube.meanMonthlyRevenue || 0)}`} />
                </div>
              </>
            ) : (
              <p>
                <Error>Couldn't load stats. Try again later.</Error>
              </p>
            )}
            <div className="metric-container-2">
              <Metric
                label="vein.gg Site Analytics"
                value={
                  <a href="https://plausible.io/vein.gg" target="_blank" className="brand-link">
                    Here
                  </a>
                }
              />
              <Metric
                label="ramjetstudios.com Site Analytics"
                value={
                  <a href="https://plausible.io/ramjetstudios.com/" target="_blank" className="brand-link">
                    Here
                  </a>
                }
              />
            </div>
            <p>
              <Link to="/" className="brand-link">
                Back to Home
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenPage;
