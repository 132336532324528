import * as React from 'react';
import Mail from '../../../../assets/socials/mail.svg';
import Twitter from '../../../../assets/socials/twitter.svg';
import Artstation from '../../../../assets/socials/artstation.svg';
import Website from '../../../../assets/socials/website.svg';
import Github from '../../../../assets/socials/github.svg';
import Twitch from '../../../../assets/socials/twitch.svg';

type PeopleCardSocial =
  | {
      type: 'email';
      email: string;
    }
  | { type: 'artstation'; username: string }
  | { type: 'twitter'; username: string }
  | { type: 'website'; url: string }
  | { type: 'github'; username: string }
  | { type: 'twitch'; username: string };

interface PeopleCardProps {
  name: string;
  title: string;
  avatar?: string;
  avatarThumb?: string;
  socials?: PeopleCardSocial[];
}

const PeopleCard: React.FC<PeopleCardProps> = (props) => {
  return (
    <div className="people-card">
      <div className="avatar-container">
        {props.avatar && props.avatarThumb && <img className="avatar" src={props.avatarThumb} alt={props.name} width="100" height="100" />}
      </div>
      <div className="people-header">{props.name}</div>
      <div className="people-role">{props.title}</div>
      <div className="people-socials">
        {(props.socials || []).map((social) => {
          if (social.type === 'email') {
            return (
              <a key={social.type} href={'mailto:' + social.email} className="people-social-link" target="_blank">
                <img src={Mail} alt="Email" width="32" height="32" />
              </a>
            );
          } else if (social.type === 'twitter') {
            return (
              <a key={social.type} href={'https://twitter.com/' + social.username} className="people-social-link" target="_blank">
                <img src={Twitter} alt="Twitter" width="32" height="32" />
              </a>
            );
          } else if (social.type === 'artstation') {
            return (
              <a key={social.type} href={'https://artstation.com/' + social.username} className="people-social-link" target="_blank">
                <img src={Artstation} alt="Artstation" width="32" height="32" />
              </a>
            );
          } else if (social.type === 'website') {
            return (
              <a key={social.type} href={social.url} className="people-social-link" target="_blank">
                <img src={Website} alt="Website" width="32" height="32" />
              </a>
            );
          } else if (social.type === 'github') {
            return (
              <a key={social.type} href={`https://github.com/${social.username}`} className="people-social-link" target="_blank">
                <img src={Github} alt="Github" width="32" height="32" />
              </a>
            );
          } else if (social.type === 'twitch') {
            return (
              <a key={social.type} href={`https://www.twitch.tv/${social.username}`} className="people-social-link" target="_blank">
                <img src={Twitch} alt="Twitch" width="32" height="32" />
              </a>
            );
          }

          return undefined;
        })}
      </div>
    </div>
  );
};

export default PeopleCard;
