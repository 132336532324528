import { useAtom } from 'jotai';
import * as React from 'react';
import styled from 'styled-components';
import { userAtom } from '../../state';
import { Link } from 'react-router-dom';

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 20px;
`;

const NavButton = styled(Link)`
  margin-left: 10px;
`;

const Nav = () => {
  const [user, _] = useAtom(userAtom);

  return (
    <NavContainer>
      <div>Hello, {user?.email}</div>
      <div>
        <NavButton className="brand-link" to="../account">
          Account
        </NavButton>
        <NavButton className="brand-link" to="../donate">
          Donate
        </NavButton>
      </div>
    </NavContainer>
  );
};

export default Nav;
