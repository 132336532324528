import { useAtom } from 'jotai';
import * as React from 'react';
import { userAtom } from '../../../state';
import styled from 'styled-components';
import { Error } from '../../elements/typography';
import Loader from '../../elements/loader';
import { Navigate } from 'react-router-dom';
import Nav from '../../elements/nav';
import { Card } from '../../elements/sections';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX } from '../../../email';
import { API_URL } from '../../../mode';
import { customList as countryCodes } from 'country-codes-list';

const countryMap = countryCodes('countryCode' as any, '{countryNameEn}') as { [key: string]: string };
countryMap[''] = 'None';

const PointerLink = styled.a`
  cursor: pointer;
`;

const BillingLinkContainer = styled.div`
  margin-top: 20px;
`;

interface IAccountForm {
  email: string;
  name: string;
  country: string;
  state: string;
  postalcode: string;
}

const Account = () => {
  const [user, setUser] = useAtom(userAtom);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const form = useForm<IAccountForm>({
    defaultValues: {
      email: user?.email,
      name: user?.name,
      country: user?.country,
      state: user?.state,
      postalcode: user?.postalcode,
    },
  });

  const logout = React.useCallback(() => {
    setUser(undefined);
  }, []);

  const update = async (payload: IAccountForm) => {
    setError('');
    setLoading(true);

    try {
      const resp = await fetch(`${API_URL}/auth`, {
        body: JSON.stringify(payload),
        method: 'PATCH',
        headers: {
          Authorization: user!.token,
          'Content-Type': 'application/json',
        },
      });

      if (resp.status === 200) {
        const obj = await resp.json();
        setUser(obj);
      } else {
        try {
          const obj = await resp.json();
          if (obj.error === 'bad_body') {
            setError('Invalid data. Make sure everything is filled in.');
          } else if (obj.error === 'email_exists') {
            setError('Email is in use.');
          } else {
            console.error(obj.error);
          }
        } catch (err) {
          setError("Couldn't update. Try again later.");
        }
      }
    } catch (err) {
      setError("Couldn't update. Try again later.");
    }

    setLoading(false);
  };

  if (!user) {
    return <Navigate to="/membership/login" />;
  }

  return (
    <div>
      <Nav />
      <Card>
        <form autoComplete="on" onSubmit={form.handleSubmit(update)}>
          <label>E-mail</label>
          <input
            className="input"
            type="text"
            placeholder="you@domain.com"
            autoComplete="email"
            defaultValue={user.email}
            {...form.register('email', { required: true, pattern: EMAIL_REGEX })}
          />
          {form.formState.errors.email?.type === 'required' && <Error>Email is required</Error>}
          {form.formState.errors.email?.type === 'pattern' && <Error>Invalid email</Error>}
          <label>Name</label>
          <input
            className="input"
            type="text"
            placeholder="First Last"
            autoComplete="name"
            defaultValue={user.name}
            {...form.register('name')}
          />
          <label>Country</label>
          <select className="input" placeholder="Country" autoComplete="country" defaultValue={user.country} {...form.register('country')}>
            {Object.keys(countryMap).map((k) => (
              <option value={k} key={k}>
                {countryMap[k]}
              </option>
            ))}
          </select>
          <label>State/Province</label>
          <input
            className="input"
            type="text"
            placeholder="State/Province"
            autoComplete="address-level1"
            defaultValue={user.state}
            {...form.register('state')}
          />
          <label>Postal Code/ZIP</label>
          <input
            className="input"
            type="text"
            placeholder="Postal Code/ZIP"
            autoComplete="postal-code"
            defaultValue={user.postalcode}
            {...form.register('postalcode')}
          />
          {loading ? <Loader /> : <button type="submit">Update</button>}
          {error && <Error>{error}</Error>}
          <BillingLinkContainer>
            <a className="brand-link" href="https://billing.stripe.com/p/login/dR65mKg0kelG0A8000" target="_blank">
              Update Billing Info
            </a>
          </BillingLinkContainer>
        </form>
      </Card>
      <p>
        <PointerLink className="brand-link" onClick={logout}>
          Logout
        </PointerLink>{' '}
      </p>
    </div>
  );
};

export default Account;
