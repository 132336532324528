import * as React from 'react';
import About from '../../sections/about/About';
import Contact from '../../sections/contact/Contact';
import Hero from '../../sections/hero/Hero';
import Vein from '../../sections/vein/Vein';

const Home = () => {
  return (
    <div className="site">
      <div className="site-inner">
        <Hero />
        <Vein />
        <About />
        <Contact />
      </div>
    </div>
  );
};

export default Home;
